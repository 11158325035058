import { PaymentAuthorizationEntity } from '@citrite/sf-api';

export async function getCreditCardAuthorizationReferrer(orderCode: string) {
	try {
		const referrerResponse =
			await PaymentAuthorizationEntity.getCreditCardAuthorizationReferrerAsync(
				orderCode
			).execute();

		return referrerResponse;
	} catch (error) {
		console.error('Error getting credit card authorization referrer', error);
	}
}
