import { UsagePlan } from '@citrite/sf-api';
import { BillingCycle, SalesforceProductCode } from '@sharefile/ecomm-models/dist/orders';
import { PlanCatalog, PlanKey, PlanName } from '@sharefile/ecomm-models/dist/plans';
import { ProductCodes } from '@sharefile/ecomm-models/dist/plans/catalog';
import { getPlanName } from '../EditBilling/utils';
import { t } from '../translate';

export interface Plan {
	additionalAccountPricingText?: string;
	baseUsers: number;
	caption: string;
	employeeAccountsText: string;
	maxFileSize: string;
	maxStorage: string;
	maxStorageText: string;
	annualRate: number;
	annualPerMonthRate: number;
	monthlyRate: number;
	name: string;
	usagePlan: UsagePlan;
	featuresText: string[];
	featuresTitle: string;
	salesforceProductCode?: SalesforceProductCode;
	planKey?: PlanKey;
}

export enum ProductName {
	IndustryAdvantage = 'ShareFile Industry Advantage',
	Advanced = 'ShareFile Advanced',
	Premium = 'ShareFile Premium',
	VDR = 'ShareFile Virtual Data Room',
}

export enum SolutionOffering {
	Standard = 'SO STANDARD',
	Advanced = 'SO ADVANCED',
	Premium = 'SO PREMIUM',
	IndustryAdvantage = 'SO INDUSTRY ADVANTAGE',
	VDR = 'SF VDR',
}

export interface PlanData {
	planName: string;
	featuresTitle: string;
	featuresText: string[];
}

export const planBuilder = (plan: PlanCatalog): Plan => {
	const planData = getPlanData(plan.productName);

	const newPlan: Plan = {
		baseUsers: plan.minQty,
		caption: planData.planName,
		employeeAccountsText: t('convert_to_paid.plan.base_employee_accounts', {
			employee: plan.minQty,
		}),
		maxFileSize: t('convert_to_paid.plan.max_file_size'),
		maxStorage: t('convert_to_paid.plan.max_storage'),
		maxStorageText: t('convert_to_paid.plan.storage', {
			storage: t('convert_to_paid.plan.max_storage'),
		}),
		annualRate: plan.annually.perUserPerYearPrice ?? plan.annually.pricePerUser,
		annualPerMonthRate: plan.annually.perUserPerMonthPrice ?? plan.annually.pricePerUser,
		monthlyRate: plan.monthly.perUserPerMonthPrice ?? plan.monthly.pricePerUser,
		name: planData.planName,
		usagePlan: usagePlanMapper(plan),
		featuresTitle: planData.featuresTitle,
		featuresText: planData.featuresText,
		salesforceProductCode: plan.productCode as SalesforceProductCode,
		planKey: getPlanName(plan.productCode) as PlanKey,
	};
	return newPlan;
};

const getPlanData = (productName: string): PlanData => {
	switch (productName) {
		case ProductName.IndustryAdvantage:
			return {
				planName: PlanName.INDUSTRY_ADVANTAGE,
				featuresTitle: t(
					'convert_to_paid.industry_advantage_plan.features.features_include'
				),
				featuresText: [
					t('convert_to_paid.industry_advantage_plan.features.accounting_workflows'),
					t(
						'convert_to_paid.industry_advantage_plan.features.integrated_client_onboarding'
					),
					t(
						'convert_to_paid.industry_advantage_plan.features.custom_tax_user_experience'
					),
					t('convert_to_paid.industry_advantage_plan.features.custom_tax_templates'),
					t('convert_to_paid.industry_advantage_plan.features.automated_engagement'),
				],
			};
		case ProductName.Advanced:
			return {
				planName: PlanName.ADVANCED,
				featuresTitle: t('convert_to_paid.advanced_plan.features.features_include'),
				featuresText: [
					t('convert_to_paid.advanced_plan.features.threat_detection_alerts'),
					t('convert_to_paid.advanced_plan.features.email_encryption'),
					t('convert_to_paid.advanced_plan.features.document_feedback'),
					t('convert_to_paid.advanced_plan.features.unlimited_client_users'),
					t('convert_to_paid.advanced_plan.features.outlook_and_gmail_plugin'),
				],
			};
		case ProductName.Premium:
			return {
				planName: PlanName.PREMIUM,
				featuresTitle: t('convert_to_paid.premium_plan.features.features_include'),
				featuresText: [
					t('convert_to_paid.premium_plan.features.all_the_esignatures'),
					t('convert_to_paid.premium_plan.features.rapid_client_onboarding'),
					t('convert_to_paid.premium_plan.features.digital_document_request_lists'),
					t('convert_to_paid.premium_plan.features.compliance_support'),
				],
			};
		case ProductName.VDR:
			return {
				planName: PlanName.VDR,
				featuresTitle: t('convert_to_paid.vdr_features.features_include'),
				featuresText: [],
			};
		default:
			return {
				planName: t('convert_to_paid.industry_advantage'),
				featuresTitle: t('convert_to_paid.industry_advantage_features.features_include'),
				featuresText: [],
			};
	}
};
export const usagePlanMapper = (plan: PlanCatalog): UsagePlan => {
	const usagePlan: UsagePlan = {
		AdditionalBandwidthPrice: 0,
		AdditionalDiskSpacePrice: 0,
		AdditionalUserPrice: 0,
		AddonTypeCode: addOnTypeCodeConvert(plan.productCode),
		AppliedAddOns: {
			AdditionalBandwidthGB: 0,
			AdditionalEmployees: 0,
			AdditionalStorageGB: 0,
		},
		AvailableAddOns: [],
		BandwidthMax: -1,
		BaseBandwidth: -1,
		BaseDiskSpace: -1,
		BasePrice: 0,
		BaseUsers: 3,
		BillingCycle: BillingCycle.Year,
		BillingRate: plan.annually.pricePerUser * plan.minQty,
		Code: addOnTypeCodeConvert(plan.productCode),
		DataCenter: 'None',
		DiskSpaceMax: -1,
		MonthlyPrice: plan.monthly.pricePerUser,
		YearlyPrice: plan.annually.pricePerUser * 12,
		Name: plan.productName,
		PlanTrack: 'Solution Offering',
		TaxQuote: { TaxRate: 0, TotalTax: 0 },
		UserLimits: { LowerLimit: 3, UpperLimit: -1 },
		UserMax: 3,
	};
	return usagePlan;
};

const addOnTypeCodeConvert = (productCode: string): string => {
	switch (productCode) {
		case ProductCodes.STANDARD:
			return SolutionOffering.Standard;
		case ProductCodes.ADVANCED:
			return SolutionOffering.Advanced;
		case ProductCodes.PREMIUM:
			return SolutionOffering.Premium;
		case ProductCodes.INDUSTRY_ADVANTAGE:
			return SolutionOffering.IndustryAdvantage;
		case ProductCodes.VDR:
			return SolutionOffering.VDR;
		default:
			return SolutionOffering.Standard;
	}
};
