import { Account, CreditCardAuthorizationReferrer } from '@citrite/sf-api/dist/Models';
import {
	CurrencyCode,
	EcommerceBillingAccountResponse,
	PaymentGatewayHPPRequest,
} from '@sharefile/ecomm-models/dist/apis/ecomm';
import {
	BillingCycle,
	OrderAction,
	SalesforceProductCode,
} from '@sharefile/ecomm-models/dist/orders';
import {
	PlanBase,
	PlanCatalog,
	PlanKey,
	ProductKey,
} from '@sharefile/ecomm-models/dist/plans';
import { BillingInfo, User } from '@sharefiledev/sharefile-appshell';
import { AxiosResponse } from 'axios';
import { v4 as uuid } from 'uuid';
import { PurchaseOrderInformation } from '../../types';
import apiClient from '../apiClient';
import { ECOMM_API_PATH } from '../common';
import {
	AccountCancellationRequest,
	PaymentGatewayFPIRequest,
	RequestStateOperation,
	SuccessFlag,
	WorldPayRedirect,
} from './models';

export const getAccountBillingInfo = async (
	accountId: string
): Promise<EcommerceBillingAccountResponse | undefined> => {
	try {
		const response = await apiClient(ECOMM_API_PATH).get(
			`/billing/account?accountId=${accountId}`
		);

		return response.data;
	} catch (error) {
		throw new Error(
			`Exception occurred while trying to get billing info, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

export const getCurrentPlan = async (accountId: string): Promise<PlanBase> => {
	try {
		const response = await apiClient(ECOMM_API_PATH).get(
			`/plans/current?accountId=${accountId}`
		);

		return response.data;
	} catch (error) {
		throw new Error(
			`Exception occurred while trying to get current plan, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

export const getPlanCatalog = async (): Promise<PlanCatalog[]> => {
	try {
		const response = await apiClient(ECOMM_API_PATH).get(
			'/plans/catalog?productCode=SFPREM001&productCode=SFADV001&productCode=SFIA001&productCode=SFVDR001'
		);
		return response.data;
	} catch (error) {
		throw new Error(
			`Exception occurred while trying to get plan catalog, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

export const setupHPPForBillingUpdate = async (account: Account) => {
	const billingInfo = await getAccountBillingInfo(account.Id!);

	if (!billingInfo) {
		throw new Error('Billing info not found');
	}

	const orderCode = generateOrderCode();
	const request: PaymentGatewayHPPRequest = createHppOrder(
		orderCode,
		billingInfo,
		OrderAction.PlanChange,
		getSuccessUrl(orderCode),
		getFailureUrl(orderCode)
	);

	return request;
};

export const getHPPForBillingUpdate = async (request: PaymentGatewayHPPRequest) => {
	return await apiClient(ECOMM_API_PATH).post<string>(
		'/paymentgateway/hostedpaymentpage',
		request
	);
};

const createHppOrder = (
	orderCode: string,
	billingInfo: EcommerceBillingAccountResponse,
	orderDescription: string,
	successUrl: string,
	failureUrl: string
): PaymentGatewayHPPRequest => {
	const request: PaymentGatewayHPPRequest = {
		orderCode,
		orderDescription,
		currency: CurrencyCode.USD,
		shopper: {
			emailAddress: billingInfo.billingContact?.email!,
			firstName: billingInfo.billingContact?.firstName!,
			lastName: billingInfo.billingContact?.lastName!,
			companyName: billingInfo.companyName,
		},
		billingAddress: {
			address1: billingInfo.billingAddress?.street!,
			city: billingInfo.billingAddress?.city!,
			state: billingInfo.billingAddress?.state!,
			country: billingInfo.billingAddress?.country!,
			zip: billingInfo.billingAddress?.postalCode!,
		},
		billingCycle: billingInfo.billingCycle,
		successUrl,
		failureUrl,
	};

	return request;
};

//#region Cancel Account

export async function cancelAccount(
	accountId: string,
	password: string,
	reason: string,
	reasonCode: number
): Promise<void> {
	const cancellationRequest: AccountCancellationRequest = {
		password,
		reason,
		reasonCode,
	};

	await apiClient(ECOMM_API_PATH).delete(`accounts/${accountId}`, {
		data: JSON.stringify(cancellationRequest),
	});
}

//#endregion

//#region RequestState

export async function requestStateOperationAllowed(
	accountId: string,
	operation: RequestStateOperation
): Promise<boolean> {
	const confirmRequest: { operation: RequestStateOperation } = {
		operation,
	};

	try {
		const response = await apiClient(ECOMM_API_PATH).post<{
			isOperationAllowed: boolean;
		}>(`request/state/${accountId}`, JSON.stringify(confirmRequest));

		return response.data.isOperationAllowed;
	} catch (err: any) {
		return true;
	}
}

//#endregion

//#region Worldpay Payment Gateway

export const getSuccessUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'success', pathParam);
};

export const getFailureUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'failure', pathParam);
};

export const getCancelUrl = (orderCode: string, pathParam?: string) => {
	return getUrl(orderCode, 'cancel', pathParam);
};

const getUrl = (orderCode: string, successFlag: SuccessFlag, pathParam?: string) => {
	if (pathParam) {
		return `${
			window.location.origin
		}/${pathParam}/${orderCode}/${successFlag}?orderId=${orderCode}&status=${successFlag.toUpperCase()}&`;
	} else {
		return `${window.location.href}/${orderCode}/failure?orderId=${orderCode}&status=FAILURE`;
	}
};

export const generateOrderCode = () => `${uuid()}`;

export const redirectToWorldpayPaymentGateway = async (
	currencyCode: CurrencyCode,
	referrer: CreditCardAuthorizationReferrer,
	account: Account,
	user: User
) => {
	try {
		const orderDescription = 'Trial conversion for account ' + account.Id;
		const orderCode = referrer.OrderCode!;

		const convertFreeTrialPathName = 'settings/admin/billing/convertfreetrial';
		const successUrl = getSuccessUrl(orderCode, convertFreeTrialPathName);
		const failureUrl = getFailureUrl(orderCode, convertFreeTrialPathName);
		const cancelUrl = getCancelUrl(orderCode, convertFreeTrialPathName);

		const referrerAddress = JSON.parse(referrer.ReferrerState!).Address;

		const request: PaymentGatewayFPIRequest = {
			orderCode,
			orderDescription,
			shopper: {
				firstName: user.FirstName!,
				lastName: user.LastName!,
				emailAddress: user.Email!,
				companyName: account.CompanyName,
			},
			billingAddress: {
				address1: referrerAddress.StreetAddress1,
				address2: referrerAddress.StreetAddress2,
				city: referrerAddress.City,
				state: referrerAddress.StateOrProvince,
				zip: referrerAddress.PostalCode,
				country: referrerAddress.Country,
			},
			successUrl,
			failureUrl,
			cancelUrl,
			currency: currencyCode,
		};

		const redirectResponse: AxiosResponse<WorldPayRedirect> = await apiClient(
			ECOMM_API_PATH
		).post<WorldPayRedirect>('/payment/gateway/fullpageintegration', request);

		window.location.href = redirectResponse.data.redirectUrl;
	} catch (error: any) {
		throw new Error(
			`Exception occurred while trying to process payment, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

//#endregion

//#region OrdersPurchase lambda

export const createOrdersPurchaseRequest = (
	orderCode: string,
	account: Account,
	billingInfo: BillingInfo,
	user: User,
	totalUsers: number,
	billingCycle: BillingCycle,
	planKey?: PlanKey,
	code?: SalesforceProductCode,
	accountId?: string
): PurchaseOrderInformation => {
	const request: PurchaseOrderInformation = {
		orderCode,
		orderPlan: {
			shopper: {
				firstName: user.FirstName!,
				lastName: user.LastName!,
				emailAddress: user.Email!,
				companyName: account.CompanyName!,
			},
			billingAddress: {
				address1: billingInfo.Address1!,
				address2: billingInfo.Address2!,
				city: billingInfo.City!,
				state: billingInfo.State!,
				zip: billingInfo.Zip!,
				country: billingInfo.Country!,
			},
			billingCycle,
			products: [
				{
					productKey: ProductKey.ShareFile,
					planKey,
					salesforceProductCode: code,
					quantity: totalUsers,
					instanceId: accountId,
				},
			],
			orderAction: OrderAction.TrialConversion,
		},
	};

	return request;
};

export const ecommOrdersPurchase = async (request: PurchaseOrderInformation) => {
	try {
		return await apiClient(ECOMM_API_PATH).post('/orders/purchase', request);
	} catch (error: any) {
		throw new Error(
			`Exception occurred while trying to process payment, details: ${JSON.stringify(
				error
			)}`
		);
	}
};

//#endregion
