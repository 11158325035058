import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import { Card, Col, Divider, Flex, Grid, Row, Skeleton, Space, Typography } from 'antd';

const { Title } = Typography;
const { useBreakpoint } = Grid;

export const SkeletonPlansChart = () => {
	const screens = useBreakpoint();

	return (
		<Flex vertical gap={sizes.LG} style={{ padding: screens.lg ? sizes.LG : 0 }}>
			<Title level={2} style={{ margin: 0 }}>
				<Skeleton active paragraph={{ rows: 0 }} title={{ width: 300 }} />
			</Title>
			<Flex vertical gap={sizes.LG}>
				<Flex vertical>
					<Flex align="center" gap="small">
						<Flex vertical={!screens.sm}>
							<Skeleton active paragraph={{ rows: 0 }} title={{ width: 400 }} />
						</Flex>
					</Flex>
					<Divider style={{ margin: `${sizes.SM} 0 0` }} />
				</Flex>
				<Flex vertical gap={16}>
					<Row>
						<Col xs={24} md={8}>
							<Title level={4}>
								<Skeleton active paragraph={{ rows: 0 }} title={{ width: 200 }} />
							</Title>
						</Col>
						<Col xs={24} md={8}>
							<Flex justify="center">
								<Skeleton.Button />
							</Flex>
						</Col>
					</Row>
					<Row gutter={[16, 16]}>
						<Col xs={24} md={8}>
							<Card loading></Card>
						</Col>
						<Col xs={24} md={8}>
							<Card loading></Card>
						</Col>
						<Col xs={24} md={8}>
							<Card loading></Card>
						</Col>
					</Row>
				</Flex>
				<Flex align="center">
					<Space>
						<Skeleton.Button />
					</Space>
				</Flex>
			</Flex>
		</Flex>
	);
};
