import React from 'react';
import { sizes } from '@sharefiledev/antd-config';
import {
	Card,
	Col,
	Divider,
	Flex,
	Grid,
	Layout,
	Row,
	Skeleton,
	theme,
	Typography,
} from 'antd';
import { StyledLayout } from './StyledLayout';

const { Content, Sider } = Layout;
const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

export const SkeletonCheckout = () => {
	const { token } = theme.useToken();
	const screens = useBreakpoint();

	return (
		<Flex vertical gap={sizes.LG} style={{ padding: screens.lg ? sizes.LG : 0 }}>
			<Title>
				<Skeleton active paragraph={{ rows: 0 }} title={{ width: 400 }} />
			</Title>
			<StyledLayout>
				<Content style={{ marginRight: sizes.LG, width: '66%' }}>
					<Row gutter={[0, screens.md ? 40 : 20]}>
						<Col span={24}>
							<Flex vertical gap="small">
								<Title level={4}>
									<Skeleton active paragraph={{ rows: 0 }} title={{ width: 200 }} />
								</Title>
								<Text>
									<Skeleton active paragraph={{ rows: 0 }} title={{ width: 600 }} />
								</Text>
								<Title level={4}>
									<Skeleton active paragraph={{ rows: 0 }} title={{ width: 100 }} />
								</Title>
							</Flex>
						</Col>
						<Col span={24}>
							<Row gutter={16}>
								<Col span={12}>
									<Card>
										<Title level={4}>
											<Skeleton active paragraph={{ rows: 2 }} title={{ width: 200 }} />
										</Title>
									</Card>
								</Col>
								<Col span={12}>
									<Card>
										<Title level={4}>
											<Skeleton active paragraph={{ rows: 2 }} title={{ width: 200 }} />
										</Title>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</Content>
				<Sider
					style={{
						borderLeft: `1px solid ${token.colorBorder}`,
						background: 'none',
						padding: sizes.MD,
					}}
					width={'33%'}
				>
					<Title>
						<Skeleton active paragraph={{ rows: 0 }} title={{ width: 200 }} />
					</Title>
					<Divider />
					<Flex vertical justify="space-evenly">
						<Flex justify="space-between">
							<Text>
								<Skeleton active paragraph={{ rows: 0 }} title={{ width: 100 }} />
							</Text>
							<Text>
								<Skeleton active paragraph={{ rows: 0 }} title={{ width: 50 }} />
							</Text>
						</Flex>
						<Flex justify="space-between">
							<Text>
								<Skeleton active paragraph={{ rows: 0 }} title={{ width: 100 }} />
							</Text>
							<Text>
								<Skeleton active paragraph={{ rows: 0 }} title={{ width: 50 }} />
							</Text>
						</Flex>
						<Flex justify="space-between">
							<Text>
								<Skeleton active paragraph={{ rows: 0 }} title={{ width: 100 }} />
							</Text>
							<Text>
								<Skeleton active paragraph={{ rows: 0 }} title={{ width: 50 }} />
							</Text>
						</Flex>
					</Flex>
					<Divider />
					<Flex justify="space-between">
						<Text>
							<Skeleton active paragraph={{ rows: 0 }} title={{ width: 100 }} />
						</Text>
						<Text>
							<Skeleton active paragraph={{ rows: 0 }} title={{ width: 50 }} />
						</Text>
					</Flex>
				</Sider>
			</StyledLayout>
		</Flex>
	);
};
