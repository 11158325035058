import * as React from 'react';
import {
	Address,
	BillingInfo,
	CreditCardAuthorizationReferrer,
	UsagePlan,
} from '@citrite/sf-api';
import {
	BillingCycle,
	SalesforceBillingCycle,
} from '@sharefile/ecomm-models/dist/orders';
import { Button, Col, notification, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
	createOrdersPurchaseRequest,
	ecommOrdersPurchase,
} from '../../../api/ecommClient/ecommApi';
import { Referrer, SuccessFlag } from '../../../api/ecommClient/models';
import { useApplicationContext } from '../../../ApplicationContextProvider';
import { ConvertFreeTrialRoute } from '../../../routes';
import { t } from '../../../translate';
import { formatCurrency } from '../../currencyFormatter';
import { Plan } from '../../planBuilder';
import { BillingAddressForm } from './BillingAddressForm';
import { CreditCardInfoPaymentGateway } from './CreditCardInfoPaymentGateway';
import { ConvertModal } from './Modals/ConvertModal';
import { PriceCard } from './PriceCard';
import { isCountrySupported } from './supportedCountries';

const { Text, Title } = Typography;

export interface BillingPaymentProps {
	annualPrice: number;
	annualPricePerMonth: number;
	monthPrice: number;
	changeBillingCycle(cycle: string): void;
	currencyCode: string;
	usagePlan: UsagePlan;
	plan: Plan;
	totalUsers: number;
	creditCardAuthorizationReferrer?: CreditCardAuthorizationReferrer;
	disableEditingSubmittedDetails?: boolean;
	successFlag?: SuccessFlag;
	setSelectedPlan: (plan?: Plan) => void;
}

export enum BillingType {
	creditCard,
	invoice,
}

export const BillingPaymentGateway = (props: BillingPaymentProps) => {
	const history = useHistory();
	const { account, user } = useApplicationContext();

	const [billingType, setBillingType] = React.useState<BillingType>(
		BillingType.creditCard
	);
	const [selectedCycle, setSelectedCycle] = React.useState<SalesforceBillingCycle>(
		props.usagePlan.BillingCycle === 'Month'
			? SalesforceBillingCycle.Monthly
			: SalesforceBillingCycle.Annual
	);
	const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
	const [showModal, setShowModal] = React.useState<boolean>(false);
	const [billingAddressInfo, setBillingAddressInfo] = React.useState<BillingInfo>({
		Address1: account.Address1,
		Address2: account.Address2,
		City: account.City,
		State: account.State,
		Zip: account.Zip,
		Country: account.Country,
		CreditCardType: 'Visa',
		CreditCardExpirationMonth: moment().format('MM'),
		CreditCardFirstName: '',
		CreditCardLastName: '',
		CreditCardExpirationYear: new Date().getFullYear().toString(),
		IsCreditCardPaymentApplicable: true,
	});

	const openModal = () => {
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const createCreditCardAuthorizationReferrerModel = (
		referrer: Referrer,
		accountId: string,
		referrerState: string
	) => {
		const newCreditCardAuthorizationReferrer: CreditCardAuthorizationReferrer = {
			OrderCode: '',
			Referrer: referrer,
			AccountId: accountId,
			ReferrerState: referrerState,
		};
		return newCreditCardAuthorizationReferrer;
	};

	const getAddressModel = (billingInfo: BillingInfo) => {
		const address: Address = {
			StreetAddress1: billingInfo.Address1,
			StreetAddress2: billingInfo.Address2,
			City: billingInfo.City,
			StateOrProvince: billingInfo.State,
			PostalCode: billingInfo.Zip,
			Country: billingInfo.Country,
			VatId: billingInfo.VatId,
		};
		return address;
	};

	const selectAnnual = () => {
		props.changeBillingCycle('Year');
		setSelectedCycle(SalesforceBillingCycle.Annual);
	};

	const selectMonthly = () => {
		props.changeBillingCycle('Month');
		setSelectedCycle(SalesforceBillingCycle.Monthly);
		changeBillingType(BillingType.creditCard);
	};

	const changeBillingType = (billingTypeToChange: BillingType) => {
		if (billingTypeToChange !== billingType) {
			setBillingType(billingTypeToChange);
		}
		setCreditCardApplicable(billingTypeToChange);
	};

	const setCreditCardApplicable = (billingTypeToChange: BillingType) => {
		updateCreditInfo({
			IsCreditCardPaymentApplicable: billingTypeToChange === BillingType.creditCard,
		});
	};

	const updateCreditInfo = (billingInfoUpdate: BillingInfo) => {
		setBillingAddressInfo(prevInfo => ({
			...prevInfo,
			...billingInfoUpdate,
		}));
	};

	const isSelectedCycle = (cycle: string) => {
		return selectedCycle === cycle;
	};

	const getCurrencyCode = () => {
		return props.creditCardAuthorizationReferrer
			? JSON.parse(props.creditCardAuthorizationReferrer.ReferrerState!).CurrencyCode
			: props.currencyCode;
	};

	const getCreditCardAuthorizationReferrerModel = () => {
		let creditCardAuthorizationReferrerModel = null;
		if (props.creditCardAuthorizationReferrer) {
			creditCardAuthorizationReferrerModel = JSON.parse(
				props.creditCardAuthorizationReferrer.ReferrerState || ''
			);
			if (billingAddressInfo?.Zip) {
				creditCardAuthorizationReferrerModel.Address =
					getAddressModel(billingAddressInfo);
				creditCardAuthorizationReferrerModel.BillingCycle = selectedCycle;
			}
			if (props.totalUsers && !creditCardAuthorizationReferrerModel.TotalUsers) {
				creditCardAuthorizationReferrerModel.TotalUsers = props.totalUsers;
			}
		}
		return createCreditCardAuthorizationReferrerModel(
			Referrer.TrialConversion,
			account.Id!,
			JSON.stringify(getReferrerState(creditCardAuthorizationReferrerModel))
		);
	};

	const getReferrerState = (ccaRef: CreditCardAuthorizationReferrer) => {
		if (props.creditCardAuthorizationReferrer) {
			return ccaRef;
		} else {
			return {
				Address: getAddressModel(billingAddressInfo!),
				Plan: props.plan,
				UsagePlan: props.usagePlan,
				TotalUsers: props.totalUsers,
				MonthPrice: props.monthPrice,
				AnnualPrice: props.annualPrice,
				AnnualPerMonthPrice: props.annualPricePerMonth,
				Account: account,
				CurrencyCode: props.currencyCode,
				BillingCycle: selectedCycle,
			};
		}
	};

	const isCountryAllowed = React.useCallback(() => {
		return isCountrySupported(billingAddressInfo.Country);
	}, [billingAddressInfo]);

	const isAddressValid = React.useCallback(() => {
		if (props.creditCardAuthorizationReferrer) {
			return true;
		}
		return (
			billingAddressInfo.Address1 &&
			!!billingAddressInfo.Address1.trim() &&
			billingAddressInfo.City &&
			!!billingAddressInfo.City.trim() &&
			billingAddressInfo.State &&
			!!billingAddressInfo.State.trim() &&
			billingAddressInfo.Zip &&
			!!billingAddressInfo.Zip.trim() &&
			billingAddressInfo.Country &&
			!!billingAddressInfo.Country.trim()
		);
	}, [billingAddressInfo, props.creditCardAuthorizationReferrer]);

	const setModalPricing = () => {
		return props.usagePlan.BillingCycle === BillingCycle.Year
			? formatCurrency(props.annualPrice, props.currencyCode)
			: formatCurrency(props.monthPrice, props.currencyCode);
	};

	const handleConvert = async () => {
		setIsProcessing(true);
		const referrerBillingCycle: SalesforceBillingCycle = JSON.parse(
			props.creditCardAuthorizationReferrer!.ReferrerState!
		).BillingCycle as SalesforceBillingCycle;
		const request = createOrdersPurchaseRequest(
			props.creditCardAuthorizationReferrer!.OrderCode!,
			account,
			getCcaRefAddress(props.creditCardAuthorizationReferrer!),
			user,
			props.totalUsers,
			referrerBillingCycle === SalesforceBillingCycle.Monthly
				? BillingCycle.Month
				: BillingCycle.Year,
			props.plan.planKey,
			props.plan.salesforceProductCode,
			props.creditCardAuthorizationReferrer!.AccountId!
		);
		const response = await ecommOrdersPurchase(request);
		if (response.status < 200 || response.status >= 300) {
			notification.error({
				message: t('convert_to_paid.something_went_wrong'),
				description: t('convert_to_paid.try_again_or_contact_support'),
			});
		} else {
			notification.success({
				message: t('convert_to_paid.purchase_confirmed'),
				description: t('convert_to_paid.successful_conversion'),
			});
			history.push('/settings/admin/overview');
		}
	};

	const handleCancel = () => {
		props.setSelectedPlan(undefined);
		history.push(ConvertFreeTrialRoute);
	};

	const getCcaRefAddress = (ccaRef: CreditCardAuthorizationReferrer): BillingInfo => {
		const ccaBillingAddress = JSON.parse(ccaRef.ReferrerState!).Address as Address;
		const newBillingAddress: BillingInfo = {
			Address1: ccaBillingAddress?.StreetAddress1 || undefined,
			Address2: ccaBillingAddress?.StreetAddress2 || undefined,
			City: ccaBillingAddress?.City || undefined,
			State: ccaBillingAddress?.StateOrProvince || undefined,
			Zip: ccaBillingAddress?.PostalCode || undefined,
			Country: ccaBillingAddress?.Country,
		};

		return newBillingAddress;
	};

	return (
		<>
			<Col span={24}>
				<Row gutter={[8, 8]}>
					<Col span={24}>
						<Title level={4}>
							{t('convert_to_paid.billing_cycle_selection.choose_billing_cycle')}
						</Title>
						<Text>
							{t('convert_to_paid.billing_cycle_selection.choose_billing_cycle_text')}
						</Text>
					</Col>
					<Col span={12}>
						<PriceCard
							label={t('convert_to_paid.plan_summary.annually')}
							details={t('convert_to_paid.billing_cycle_selection.annual_save')}
							price={
								(props.creditCardAuthorizationReferrer &&
									JSON.parse(props.creditCardAuthorizationReferrer.ReferrerState!)
										.AnnualPricePerMonth) ||
								props.annualPricePerMonth
							}
							onClick={selectAnnual}
							isSelected={isSelectedCycle(SalesforceBillingCycle.Annual)}
							currencyCode={getCurrencyCode() ?? 'USD'}
							data-testid="annual-price-card"
						/>
					</Col>
					<Col span={12}>
						<PriceCard
							label={t('convert_to_paid.plan_summary.monthly')}
							details={t('convert_to_paid.billing_cycle_selection.online_only')}
							price={
								(props.creditCardAuthorizationReferrer &&
									JSON.parse(props.creditCardAuthorizationReferrer.ReferrerState!)
										.MonthPrice) ||
								props.monthPrice
							}
							onClick={selectMonthly}
							isSelected={isSelectedCycle(SalesforceBillingCycle.Monthly)}
							currencyCode={getCurrencyCode() ?? 'USD'}
							data-testid="monthly-price-card"
						/>
					</Col>
				</Row>
			</Col>
			<Col span={24} className="billingAddress">
				<Title level={4}>{t('convert_to_paid.billing_address.title')}</Title>
				<BillingAddressForm
					billingAddress={
						props.creditCardAuthorizationReferrer
							? getCcaRefAddress(props.creditCardAuthorizationReferrer)
							: billingAddressInfo
					}
					updateBillingAddressInfo={updateCreditInfo}
					isSFHybrisCCInfoSuccessfullyUpdated={props.disableEditingSubmittedDetails}
				/>
			</Col>
			{billingType === BillingType.creditCard && (
				<Col span={24}>
					<Title level={4}>{t('convert_to_paid.credit_card.information')}</Title>
					<CreditCardInfoPaymentGateway
						creditCardAuthorizationReferrer={getCreditCardAuthorizationReferrerModel()}
						isEditCreditCardInfoDisabled={
							!isAddressValid() ||
							props.disableEditingSubmittedDetails ||
							!isCountryAllowed()
						}
						account={account}
						user={user}
						successFlag={props.successFlag}
					/>
				</Col>
			)}
			<Col span={24}>
				<Tooltip
					placement="top"
					title={
						!isAddressValid() || props.successFlag !== 'success'
							? t('convert_to_paid.buttons.convert_now_tooltip')
							: null
					}
				>
					<Button
						type="primary"
						onClick={() => openModal()}
						disabled={
							isProcessing ||
							!isCountryAllowed() ||
							!isAddressValid() ||
							props.successFlag !== 'success'
						}
						data-testid="convert-now-button"
					>
						{t('convert_to_paid.buttons.convert_now')}
					</Button>
				</Tooltip>
				<Button onClick={handleCancel} data-testid="cancel-button">
					{t('convert_to_paid.buttons.cancel')}
				</Button>
			</Col>
			{showModal && (
				<ConvertModal
					price={setModalPricing()}
					onClose={closeModal}
					handleConvert={async () => {
						await handleConvert();
					}}
				/>
			)}
		</>
	);
};
