import axios from 'axios';

const baseUrl = () => {
	// NOTE: use for local debugging 'http://localhost:8080';
	return window.origin;
};

const ApiClient = (apiBaseUrl: string) => {
	return axios.create({
		baseURL: `${baseUrl()}${apiBaseUrl}`,
		headers: {
			'X-BFF-CSRF': 'true',
		},
	});
};

export default ApiClient;
