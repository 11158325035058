import * as React from 'react';
import { PlanName } from '@sharefile/ecomm-models/dist/plans';
import { sizes } from '@sharefiledev/antd-config';
import { Check, NewFolder, UserSingleNeutral } from '@sharefiledev/icons';
import { Button, Card, Col, Flex, Grid, Row, Tag, theme, Typography } from 'antd';
import { t } from '../../translate';
import { Plan } from '../planBuilder';

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

export interface PlanCardProps {
	plan: Plan;
	onSelectPlan(): void;
	price: string;
}

export const PlanCard = (props: PlanCardProps) => {
	const { token } = theme.useToken();
	const screens = useBreakpoint();
	const isDesktop = screens.xl;

	const isPremium = () => {
		return props.plan.name === PlanName.PREMIUM;
	};
	const isIndustryAdvantage = () => {
		return props.plan.name === PlanName.INDUSTRY_ADVANTAGE;
	};

	return (
		<Card
			style={{ height: '100%' }}
			styles={{
				body: { height: '100%' },
			}}
		>
			<Flex vertical gap={24}>
				<Flex>
					{isIndustryAdvantage() ? (
						<Tag
							style={{
								border: 'none',
								marginBottom: !screens.lg ? sizes.MS : '0px',
								color: token.colorPrimaryText,
							}}
							color={token.colorPrimaryBg}
						>
							{t('convert_to_paid.industry_advantage_plan.sharefile_for_accounting')}
						</Tag>
					) : isPremium() ? (
						<Flex gap={sizes.XXS} style={{ flexWrap: 'wrap' }}>
							<Tag
								style={{ border: 'none', color: token.colorInfoText }}
								color={token.colorInfoBg}
							>
								{t('convert_to_paid.premium_plan.cloud_storage')}
							</Tag>
							<Tag
								style={{ border: 'none', color: token.colorSuccess }}
								color={token.colorSuccessBg}
							>
								{t('convert_to_paid.premium_plan.most_popular')}
							</Tag>
						</Flex>
					) : (
						<div style={{ height: !screens.lg ? '44px' : '20px' }}></div>
					)}
				</Flex>
				<Flex gap={8} vertical>
					<Title
						level={5}
						style={{ margin: '0' }}
						data-testid={`plan-title-${props.plan.name}`}
					>
						{props.plan.caption}
					</Title>

					<Flex align={isDesktop ? 'flex-end' : 'flex-start'} vertical={!isDesktop}>
						<Title
							level={1}
							style={{ margin: 0 }}
							data-testid={`plan-price-${props.plan.name}`}
						>
							{props.price}
						</Title>
						<Text style={{ marginLeft: sizes.XXS, paddingBottom: sizes.XXS }}>
							{t('convert_to_paid.month')}
						</Text>
					</Flex>
				</Flex>
				<Flex vertical>
					<Row style={{ marginBottom: sizes.XS }}>
						<UserSingleNeutral size={!screens.lg ? 16 : 20} />
						<Text style={{ marginLeft: !screens.lg ? sizes.XXS : sizes.SM }}>
							{props.plan.employeeAccountsText}
						</Text>
					</Row>
					<Row>
						<NewFolder size={!screens.lg ? 16 : 20} />
						<Text style={{ marginLeft: !screens.lg ? sizes.XXS : sizes.SM }}>
							{props.plan.maxStorageText}
						</Text>
					</Row>
				</Flex>
				<Flex>
					<Button
						type="default"
						onClick={() => props.onSelectPlan()}
						data-testid={`upgrade-button-${props.plan.name}`}
					>
						{t('convert_to_paid.buttons.upgrade')}
					</Button>
				</Flex>
				<Row>
					<Text>{props.plan.featuresTitle}</Text>
					{props.plan.featuresText.map(feature => (
						<Col span={24} key={feature} style={{ marginTop: sizes.SM }}>
							<Flex justify="flex-start" align="flex-start">
								<Flex style={{ marginTop: '2px' }}>
									<Check size={16} />
								</Flex>
								<Text style={{ marginLeft: sizes.SM }}>{feature}</Text>
							</Flex>
						</Col>
					))}
				</Row>
			</Flex>
		</Card>
	);
};
