import React from 'react';
import { SalesforceBillingCycle } from '@sharefile/ecomm-models/dist/orders';
import { sizes } from '@sharefiledev/antd-config';
import { HelpQuestion, LinkShare } from '@sharefiledev/icons';
import {
	Button,
	Col,
	Divider,
	Flex,
	Grid,
	Row,
	Segmented,
	Space,
	Typography,
} from 'antd';
import { t } from '../../translate';
import { formatCurrency } from '../currencyFormatter';
import { Plan } from '../planBuilder';
import { PlanCard } from './PlanCard';

const { Title, Text, Link } = Typography;
const { useBreakpoint } = Grid;

export interface PlanChartProps {
	onSelectPlan(plan: Plan, cycle?: string): void;
	plans: Plan[];
	currencyCode: string;
}

export const PlansChart = (props: PlanChartProps) => {
	const [selectedCycle, setSelectedCycle] = React.useState<SalesforceBillingCycle>(
		SalesforceBillingCycle.Annual
	);
	const screens = useBreakpoint();
	const payMonthly = t('convert_to_paid.billing_cycle_selection.pay_monthly');
	const payAnnually = t('convert_to_paid.billing_cycle_selection.pay_annually');

	const billingCycleOptions = [
		{
			label: payMonthly,
			value: SalesforceBillingCycle.Monthly,
		},
		{
			label: payAnnually,
			value: SalesforceBillingCycle.Annual,
		},
	];

	const calculatePriceByCycle = (plan: Plan) => {
		if (selectedCycle === SalesforceBillingCycle.Monthly) {
			return formatCurrency(plan.monthlyRate * plan.baseUsers, props.currencyCode);
		} else {
			return formatCurrency(plan.annualPerMonthRate * plan.baseUsers, props.currencyCode);
		}
	};

	return (
		<Flex vertical gap={sizes.LG}>
			<Flex vertical>
				<Flex align="center" gap="small">
					<Flex vertical={!screens.sm}>
						<Flex gap={4}>
							<HelpQuestion size={16} />
							<Text>{t('convert_to_paid.plan_chart.need_help')}</Text>
						</Flex>
					</Flex>
				</Flex>
				<Divider style={{ margin: `${sizes.SM} 0 0` }} />
			</Flex>
			<Flex vertical gap={16}>
				<Row>
					<Col xs={24} md={8}>
						<Title level={4}>{t('convert_to_paid.plan_chart.upgrade_plan')}</Title>
					</Col>
					<Col xs={24} md={8}>
						<Flex justify="center">
							<Segmented
								options={billingCycleOptions}
								onChange={setSelectedCycle}
								defaultValue={SalesforceBillingCycle.Annual}
							/>
						</Flex>
					</Col>
				</Row>
				<Row gutter={[16, 16]}>
					{props.plans.map(plan => (
						<Col xs={24} md={8} key={plan.name}>
							<PlanCard
								plan={plan}
								onSelectPlan={() => props.onSelectPlan(plan, selectedCycle)}
								price={calculatePriceByCycle(plan)}
							/>
						</Col>
					))}
				</Row>
			</Flex>

			<Flex align="center">
				{props.plans.length > 1 && (
					<Space>
						<Link href="https://sharefile.com/pricing" target="_blank">
							<Button
								icon={<LinkShare size={16} />}
								iconPosition="end"
								data-testid="view-detailed-button"
							>
								{t('convert_to_paid.plan_chart.view_detailed_comparison')}
							</Button>
						</Link>
					</Space>
				)}
			</Flex>
		</Flex>
	);
};
