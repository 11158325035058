import { Address, CreditCardAuthorizationReferrer } from '@citrite/sf-api';
import { BillingAddressResponse } from '@sharefile/ecomm-models/dist/apis/ecomm/';
import { Referrer } from './ecommClient/models';

export const ECOMM_API_PATH = '/io/ecommerce';

export function getAddressModel(billingAddress: BillingAddressResponse) {
	const address: Address = {
		StreetAddress1: billingAddress.street,
		City: billingAddress.city,
		StateOrProvince: billingAddress.state,
		PostalCode: billingAddress.postalCode,
		Country: billingAddress.country,
	};
	return address;
}

export function getCreditCardAuthorizationReferrerModel(
	referrer: Referrer,
	accountId: string,
	referrerState: string
) {
	const creditCardAuthorizationReferrer: CreditCardAuthorizationReferrer = {
		OrderCode: '',
		Referrer: referrer,
		AccountId: accountId,
		ReferrerState: referrerState,
	};
	return creditCardAuthorizationReferrer;
}
