import React from 'react';
import { Grid, Layout } from 'antd';

const { useBreakpoint } = Grid;

export const StyledLayout: React.FC = props => {
	const screens = useBreakpoint();

	return (
		<Layout
			style={{
				background: 'none',
				flexDirection: screens.md ? 'row' : 'column-reverse',
			}}
		>
			{props.children}
		</Layout>
	);
};
