import { numberWithCurrencyStyle } from '@csg/g11n-sdk-core';
import {
	BillingCycle,
	SalesforceBillingCycle,
} from '@sharefile/ecomm-models/dist/orders';
import {
	PlanInfo,
	ProductCodes,
	QuoteRequest,
	QuoteRequestProduct,
	QuoteResponse,
} from '@sharefile/ecomm-models/dist/plans';
import { getLanguage, t } from '../translate';

export function mapBillingCycle(billingCycle: SalesforceBillingCycle): BillingCycle {
	switch (billingCycle) {
		case SalesforceBillingCycle.Monthly:
			return BillingCycle.Month;
		case SalesforceBillingCycle.Annual:
			return BillingCycle.Year;
		case SalesforceBillingCycle.Quarterly:
			return BillingCycle.Quarter;
	}
	return BillingCycle.Month;
}

export function formatPrice(price: number): string {
	return numberWithCurrencyStyle(price, 'USD', getLanguage(), 2);
}

export function formatStorage(storageGb: number): string {
	if (storageGb < 1000) {
		return t('manage_plan.modals.value_gb', {
			value: storageGb,
		});
	}

	return t('manage_plan.modals.value_tb', {
		value: storageGb / 1000,
	});
}

export function getPlanName(code: string): string {
	switch (code) {
		case ProductCodes.STANDARD:
			return t('manage_plan.standard.title');
		case ProductCodes.STANDARD0GB:
			return t('manage_plan.standard.title_0gb');
		case ProductCodes.ADVANCED:
			return t('manage_plan.advanced.title');
		case ProductCodes.ADVANCED0GB:
			return t('manage_plan.advanced.title_0gb');
		case ProductCodes.PREMIUM:
			return t('manage_plan.premium.title');
		case ProductCodes.VDR:
			return t('manage_plan.vdr.title');
		case ProductCodes.CONNECTORS0GB:
			return t('manage_plan.connectors.title_0gb');
		case ProductCodes.INDUSTRY_ADVANTAGE:
			return t('manage_plan.industry_advantage.title');
		default:
			return '';
	}
}

export const is0gbPlan = (productCode: string): boolean =>
	[
		ProductCodes.ADVANCED0GB,
		ProductCodes.STANDARD0GB,
		ProductCodes.CONNECTORS0GB,
	].includes(productCode);

export const isRegularSfPlan = (productCode: string): boolean =>
	[
		ProductCodes.ADVANCED,
		ProductCodes.PREMIUM,
		ProductCodes.STANDARD,
		ProductCodes.INDUSTRY_ADVANTAGE,
		ProductCodes.ADVANCED0GB,
		ProductCodes.STANDARD0GB,
		ProductCodes.CONNECTORS0GB,
		ProductCodes.VDR,
	].includes(productCode);

export const createQuoteRequest = (
	planCode: string,
	usersQty: number,
	billingCycle: BillingCycle,
	addonStorageQty?: number
): QuoteRequest => {
	const products: QuoteRequestProduct[] = [
		{
			productCode: planCode,
			quantity: usersQty,
		},
	];
	if (addonStorageQty) {
		products.push({
			productCode: ProductCodes.STORAGEPACK3TB,
			quantity: addonStorageQty,
		});
	}
	return {
		isTrial: false,
		products,
		billingCycle,
	};
};

export function getPlanQuote(quote: QuoteResponse) {
	return quote?.products?.find(x => isRegularSfPlan(x.productCode));
}

export function get3TbAddOnQuote(quote: QuoteResponse) {
	return quote?.products?.find(x => x.productCode === ProductCodes.STORAGEPACK3TB);
}

export function groupPlanInfo(orders: PlanInfo[]): PlanInfo[] {
	return orders.reduce((result: PlanInfo[], current) => {
		const existingPlanInfo = result.find(
			group => group.productCode === current.productCode
		);

		if (existingPlanInfo) {
			existingPlanInfo.quantity += current.quantity;
			existingPlanInfo.price += current.price;
		} else {
			result.push(current);
		}

		return result;
	}, []);
}

// export function getQueryStringParameter(parameter: string, search: string) {
// 	const uri = new URI(search);
// 	if (uri.hasQuery(parameter)) {
// 		return uri.query(true)[parameter];
// 	}
// 	return '';
// }
