import * as React from 'react';
import { BillingCycle } from '@sharefile/ecomm-models/dist/orders';
import { sizes } from '@sharefiledev/antd-config';
import { Divider, Flex, Grid, Typography } from 'antd';
import { t } from '../../translate';
import { formatCurrency } from '../currencyFormatter';
import { Plan } from '../planBuilder';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

export interface PlanSummaryProps {
	billingCycle: string;
	annualPrice: number;
	monthPrice: number;
	currencyCode: string;
	plan: Plan;
	users: number;
	storage: number;
	isVDR: boolean;
}

export const PlanSummary = (props: PlanSummaryProps) => {
	const screens = useBreakpoint();

	const getBillingCycleText = () => {
		switch (props.billingCycle) {
			case BillingCycle.Year:
				return t('convert_to_paid.plan_summary.annually');
			case BillingCycle.Month:
				return t('convert_to_paid.plan_summary.monthly');
		}
	};
	return (
		<>
			<Title level={5} data-testid="plan-name">
				{t('convert_to_paid.plan_summary.plan', { name: props.plan.name })}
			</Title>
			{!props.isVDR && (
				<>
					<Text>{t('convert_to_paid.plan_summary.storage_max_file_size')}</Text>
					&nbsp;
					<Text strong>
						{props.plan.maxStorage}&nbsp;/{props.plan.maxFileSize}
					</Text>
					{screens.md && <Divider />}
				</>
			)}
			<Flex vertical justify="space-evenly">
				<Flex justify="space-between">
					<Text>{t('convert_to_paid.plan_summary.billing')}</Text>

					<Text style={{ wordBreak: 'normal' }} data-testid="billing-cycle">
						{getBillingCycleText()}
					</Text>
				</Flex>
				{props.isVDR ? (
					<>
						<Flex justify="space-between">
							<Text>{t('convert_to_paid.plan_summary.employee_licenses')}</Text>
							<Text>{props.users}</Text>
						</Flex>
						<Flex justify="space-between">
							<Text>{t('convert_to_paid.plan_summary.storage_only')}</Text>
							<Text>
								{t('convert_to_paid.plan_summary.storage_gb', { storage: props.storage })}
							</Text>
						</Flex>
					</>
				) : (
					<Flex justify="space-between">
						<Text>{t('convert_to_paid.plan_summary.users')}</Text>

						<Text>{props.users}</Text>
					</Flex>
				)}

				<Flex>
					<Text>{t('convert_to_paid.plan_summary.plus_applicable_taxes')}</Text>
				</Flex>
				{screens.md && <Divider />}
				<Flex justify="space-between">
					<Title level={5} style={{ marginTop: sizes.XS }}>
						{t('convert_to_paid.plan_summary.your_total')}
					</Title>

					<Title level={5} style={{ marginTop: sizes.XS }} data-testid="plan-total">
						{props.billingCycle === 'Year'
							? formatCurrency(props.annualPrice, props.currencyCode)
							: formatCurrency(props.monthPrice, props.currencyCode)}
					</Title>
				</Flex>
			</Flex>
		</>
	);
};
