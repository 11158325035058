import React from 'react';
import { BillingEntity } from '@citrite/sf-api';
import { CurrencyCode } from '@sharefile/ecomm-models/dist/apis/ecomm';
import {
	BillingCycle,
	SalesforceBillingCycle,
} from '@sharefile/ecomm-models/dist/orders';
import { PlanName } from '@sharefile/ecomm-models/dist/plans';
import { sizes } from '@sharefiledev/antd-config';
import { Flex, Grid, notification, Typography } from 'antd';
import { useAsync } from 'react-async-hook';
import { useHistory, useParams } from 'react-router';
import { getCurrentPlan, getPlanCatalog } from '../api/ecommClient/ecommApi';
import { SuccessFlag } from '../api/ecommClient/models';
import { useApplicationContext } from '../ApplicationContextProvider';
import { t } from '../translate';
import { Plan, planBuilder, ProductName } from './planBuilder';
import { getCreditCardAuthorizationReferrer } from './PlanDetails/Billing/paymentAuthorization';
import { PlanDetailsPaymentGateway } from './PlanDetails/PlanDetailsPaymentGateway';
import { SkeletonCheckout } from './PlanDetails/SkeletonCheckout';
import { PlansChart } from './PlansChart/PlansChart';
import { SkeletonPlansChart } from './PlansChart/SkeletonPlansChart';

const { Title } = Typography;
const { useBreakpoint } = Grid;

interface Params {
	[key: string]: string | undefined;
	orderId?: string;
	successFlag?: SuccessFlag;
}

const ConvertToPaid = () => {
	const history = useHistory();
	const { orderId, successFlag } = useParams<Params>();
	const screens = useBreakpoint();
	const { account } = useApplicationContext();

	const [selectedPlan, setSelectedPlan] = React.useState<Plan>();
	const [currentTrialPlan, setCurrentTrialPlan] = React.useState<string>();
	const [currencyCode, setCurrencyCode] = React.useState(CurrencyCode.USD);

	const selectPlan = (plan: Plan, cycle?: SalesforceBillingCycle) => {
		if (cycle && cycle === SalesforceBillingCycle.Monthly) {
			plan.usagePlan.BillingCycle = BillingCycle.Month;
		}
		setSelectedPlan(plan);
	};

	const getCurrentPlanAndBillingInfo = async () => {
		try {
			const currentPlanResponse = await getCurrentPlan(account.Id!);
			setCurrentTrialPlan(currentPlanResponse.productName);
			const getBillingInfo = await BillingEntity.getBillingInfo().execute();
			if (getBillingInfo.CurrencyCode) {
				setCurrencyCode(getBillingInfo.CurrencyCode as CurrencyCode);
			}
		} catch (error) {
			throw new Error(
				`Exception occurred while trying to get current plan and billing info, details: ${JSON.stringify(
					error
				)}`
			);
		}
	};

	const getCCAReferrerAsync = useAsync(async () => {
		if (successFlag && orderId) {
			try {
				if (successFlag.toLowerCase() === 'success') {
					notification.success({
						message: t('convert_to_paid.credit_card.confirmed'),
						description: t('convert_to_paid.credit_card.confirmed_description'),
					});
				} else if (successFlag.toLowerCase() === 'failure') {
					notification.error({
						message: t('convert_to_paid.something_went_wrong'),
						description: t('convert_to_paid.try_again_or_contact_support'),
					});
				}
				const referrer = await getCreditCardAuthorizationReferrer(orderId);
				if (referrer) {
					setSelectedPlan(JSON.parse(referrer.ReferrerState!).Plan);
					return referrer;
				}
			} catch {
				history.push('/settings/admin/overview');
				notification.error({
					message: t('convert_to_paid.something_went_wrong'),
					description: t('convert_to_paid.try_again_or_contact_support'),
				});
			}
		}
	}, [successFlag, orderId]);

	const asyncPlanContent = useAsync(
		async () => {
			await getCurrentPlanAndBillingInfo();
			const planCatalog = await getPlanCatalog();

			if (planCatalog) {
				if (account.PlanTrack === PlanName.VDR) {
					return planCatalog
						.filter(plan => plan.productName === ProductName.VDR)
						.map(plan => {
							return planBuilder(plan);
						});
				} else {
					return planCatalog
						.filter(plan => plan.productName !== ProductName.VDR)
						.map(plan => {
							return planBuilder(plan);
						});
				}
			}
		},
		[],
		{
			onError: () => {
				history.push('/settings/admin/overview');
				notification.error({
					message: t('convert_to_paid.error_loading_plans'),
				});
			},
		}
	);

	if (
		!orderId &&
		(asyncPlanContent.loading || asyncPlanContent.status === 'not-requested')
	) {
		return <SkeletonPlansChart />;
	}

	if (getCCAReferrerAsync.loading) {
		return <SkeletonCheckout />;
	}

	return (
		<Flex vertical gap={sizes.LG} style={{ padding: screens.lg ? sizes.LG : 0 }}>
			<Title level={2} style={{ margin: 0 }}>
				{t('convert_to_paid.title')}
			</Title>
			{selectedPlan || (selectedPlan && currentTrialPlan === ProductName.VDR) ? (
				<PlanDetailsPaymentGateway
					plan={selectedPlan}
					currencyCode={currencyCode}
					creditCardAuthorizationReferrer={getCCAReferrerAsync.result}
					successFlag={successFlag}
					setSelectedPlan={setSelectedPlan}
				/>
			) : (
				asyncPlanContent.result && (
					<PlansChart
						onSelectPlan={selectPlan}
						plans={asyncPlanContent.result}
						currencyCode={currencyCode}
					/>
				)
			)}
		</Flex>
	);
};

export default ConvertToPaid;
