import React from 'react';
import { Spin } from 'antd';
import { EditBillingOverviewProps } from './EditBillingOverview';
import { ManagePlanProps } from './ManagePlan';

const LazyEditBillingOverview = React.lazy(() => import('./EditBillingOverview'));
export const AsyncEditBillingOverview: React.FC<EditBillingOverviewProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyEditBillingOverview {...props} />
	</React.Suspense>
);

const LazyManagePlan = React.lazy(() => import('./ManagePlan'));
export const AsyncManagePlan: React.FC<ManagePlanProps> = props => (
	<React.Suspense fallback={<Spin />}>
		<LazyManagePlan {...props} />
	</React.Suspense>
);
