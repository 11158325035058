import * as React from 'react';
import {
	AppliedPlanAddOns,
	CreditCardAuthorizationReferrer,
	UsagePlan,
} from '@citrite/sf-api';
import { PlanName } from '@sharefile/ecomm-models/dist/plans';
import { sizes } from '@sharefiledev/antd-config';
import { Col, Flex, Grid, Layout, Row, Space, theme, Typography } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { SuccessFlag } from '../../api/ecommClient/models';
import { t } from '../../translate';
import { Plan } from '../planBuilder';
import { BillingPaymentGateway } from './Billing/BillingPaymentGateway';
import { StyledInputNumber } from './PlanDetailsPaymentGateway.styled';
import { PlanSummary } from './PlanSummary';
import { StyledLayout } from './StyledLayout';
const { Text, Title } = Typography;
const { Content } = Layout;

export interface Props {
	plan: Plan;
	currencyCode: string;
	creditCardAuthorizationReferrer?: CreditCardAuthorizationReferrer;
	disableEditingSubmittedDetails?: boolean;
	successFlag?: SuccessFlag;
	setSelectedPlan: (plan?: Plan) => void;
}

export const PlanDetailsPaymentGateway = (props: Props) => {
	const parsedRefererState = props.creditCardAuthorizationReferrer?.ReferrerState
		? JSON.parse(props.creditCardAuthorizationReferrer.ReferrerState)
		: null;

	const currentUsagePlan: UsagePlan = parsedRefererState
		? (parsedRefererState.UsagePlan as UsagePlan)
		: props.plan.usagePlan;

	const usersCount = parsedRefererState
		? parsedRefererState.TotalUsers
		: currentUsagePlan.BaseUsers! + currentUsagePlan.AppliedAddOns!.AdditionalEmployees!;

	const currentPlanUsers = currentUsagePlan.UserMax!;
	const isVDR = props.plan.usagePlan.Name === PlanName.VDR;

	const [usagePlan, setUsagePlan] = React.useState<UsagePlan>(currentUsagePlan);
	const [totalUsers, setTotalUsers] = React.useState<number>(usersCount);
	const [totalStorage, setTotalStorage] = React.useState<number>(usersCount);

	const { useBreakpoint } = Grid;
	const screens = useBreakpoint();
	const { token } = theme.useToken();

	const isMobilePlanSummary = () => {
		return screens.md
			? {
					borderLeft: `1px solid ${token.colorBorder}`,
					background: 'none',
					padding: sizes.MD,
			  }
			: {
					border: `1px solid ${token.colorBorder}`,
					borderRadius: token.borderRadius,
					background: 'none',
					padding: token.padding,
					marginBottom: sizes.MD,
			  };
	};

	const changeBillingCycle = (cycle: string) => {
		updatePlan({ BillingCycle: cycle });
	};

	const updatePlan = (newPlan: UsagePlan) => {
		const newUsagePlan = { ...usagePlan, ...newPlan };
		setUsagePlan(prev => ({ ...prev, ...newPlan }));
		setTotalUsers(
			props.plan.baseUsers! + (newUsagePlan?.AppliedAddOns?.AdditionalEmployees ?? 0)
		);
		setTotalStorage(
			totalStorage + (newUsagePlan?.AppliedAddOns?.AdditionalEmployees ?? 0)
		);
	};

	const updateUsers = (value: number) => {
		if (value === -1) {
			value = 0;
		}
		const difference = value - props.plan.baseUsers;
		updateAddons({ AdditionalEmployees: difference });
	};

	const updateAddons = (addons: AppliedPlanAddOns) => {
		updatePlan({
			AppliedAddOns: { ...usagePlan.AppliedAddOns, ...addons },
		});
	};

	const handleUserIncrease = () => {
		updateUsers(totalUsers + 1);
	};

	const handleUserDecrease = () => {
		updateUsers(totalUsers - 1);
	};

	return (
		<StyledLayout>
			<Content
				style={{ marginRight: sizes.LG, width: '100%' }}
				data-testid="plan-details-page"
			>
				<Row gutter={[0, screens.md ? 40 : 20]}>
					{isVDR ? (
						<Col span={24}>
							<Space>
								<Title level={4}>{t('convert_to_paid.employee_licenses_storage')}</Title>
								<Text>
									{t('convert_to_paid.employee_accounts_description_vdr', {
										users: currentPlanUsers,
										storage: currentPlanUsers, // ShareFile VDR allocates 1GB per user
									})}
								</Text>
								<Text>{t('convert_to_paid.storage_distribution_description')}</Text>
							</Space>
						</Col>
					) : (
						<Col span={24}>
							<Flex vertical gap="middle">
								<Title level={4} style={{ margin: 0 }}>
									{t('convert_to_paid.number_of_users_selection.title')}
								</Title>
								<Text>
									{t('convert_to_paid.employee_accounts_description', {
										users: currentPlanUsers,
									})}
								</Text>
								<StyledInputNumber
									controls
									value={totalUsers}
									onStep={(_value: any, info: { type: string }) => {
										info.type === 'up' ? handleUserIncrease() : handleUserDecrease();
									}}
									min={props.plan.baseUsers}
									data-testid="employee-number-input"
								/>
							</Flex>
						</Col>
					)}
					{isVDR && (
						<Row>
							<Text>
								{t('convert_to_paid.current_license', {
									users: totalUsers,
								})}
							</Text>
							<Text>
								{t('convert_to_paid.current_storage', {
									storage: totalStorage,
								})}
							</Text>
						</Row>
					)}
					<BillingPaymentGateway
						annualPrice={props.plan.annualRate * totalUsers}
						annualPricePerMonth={props.plan.annualPerMonthRate * totalUsers}
						monthPrice={props.plan.monthlyRate * totalUsers}
						changeBillingCycle={changeBillingCycle}
						currencyCode={props.currencyCode}
						usagePlan={usagePlan}
						plan={props.plan}
						totalUsers={totalUsers!}
						creditCardAuthorizationReferrer={props.creditCardAuthorizationReferrer}
						disableEditingSubmittedDetails={props.disableEditingSubmittedDetails}
						successFlag={props.successFlag}
						setSelectedPlan={props.setSelectedPlan}
					/>
				</Row>
			</Content>
			<Sider style={isMobilePlanSummary()} width={screens.md ? '33%' : '100%'}>
				<PlanSummary
					plan={
						(!!props.creditCardAuthorizationReferrer &&
							JSON.parse(props.creditCardAuthorizationReferrer!.ReferrerState!).Plan) ||
						props.plan
					}
					billingCycle={usagePlan.BillingCycle!}
					users={totalUsers!}
					storage={totalStorage!}
					annualPrice={props.plan.annualRate * totalUsers}
					monthPrice={props.plan.monthlyRate * totalUsers}
					currencyCode={props.currencyCode}
					isVDR={isVDR}
				/>
			</Sider>
		</StyledLayout>
	);
};
