import React from 'react';
import {
	Api,
	BillingInfo,
	CountriesEntity,
	Country,
	CountrySubdivision,
} from '@citrite/sf-api';
import { sort } from '@citrite/sf-sorting';
import { Alert, Col, Form, Input, Row, Select, theme } from 'antd';
import { useAsync } from 'react-async-hook';
import { t } from '../../../translate';
import { isCountrySupported } from './supportedCountries';
const { sortDirections } = Api;

export interface BillingAddressProps {
	billingAddress: BillingInfo;
	updateBillingAddressInfo(billingAddress: BillingInfo): void;
	disableCountries?: boolean;
	isSFHybrisCCInfoSuccessfullyUpdated?: boolean;
}
export const BillingAddressForm = (props: BillingAddressProps) => {
	const { token } = theme.useToken();
	const [form] = Form.useForm();

	const getCountries = async (): Promise<Country[]> => {
		const countriesRes = await CountriesEntity.get().execute();
		const sortedCountries = getSortedCountries(countriesRes.value || []);
		const usa = sortedCountries.filter(country => {
			return country.Id === 'US';
		});
		sortedCountries.splice(
			sortedCountries.findIndex(({ Id }) => Id === 'US'),
			1
		);
		sortedCountries.unshift(usa[0]);
		return sortedCountries;
	};

	const getSortedCountries = (countriesList: Country[]) => {
		const filteredCountries = countriesList.filter(c => c.Id !== '*' && c.Id !== '-');
		return sort(filteredCountries, {
			key: 'Name',
			direction: sortDirections.ASCENDING,
		});
	};

	const getSortedSubdivisions = (subdivisionsList: CountrySubdivision[]) => {
		return sort(
			subdivisionsList.map(c => {
				if (c.Id === '*') {
					c.Id = undefined;
				} else if (c.Id === '-') {
					c.Id = '';
				}
				return c;
			}),
			{
				key: 'Name',
				direction: sortDirections.ASCENDING,
			}
		);
	};

	const getSubdivisions = async (value: string) => {
		if (
			!props.billingAddress.Country ||
			!isCountrySupported(props.billingAddress.Country)
		) {
			return [];
		}
		const countryId = CountriesEntity.get(value).getUrl();
		const country = await CountriesEntity.get(countryId).execute();

		const sortedSubdivisions = getSortedSubdivisions(country.Subdivisions || []);
		if (!props.billingAddress?.State) {
			if (sortedSubdivisions.length > 0) {
				setStateOrProvince(sortedSubdivisions[0]?.Id ?? '');
			}
		}
		return sortedSubdivisions;
	};

	const setCountry = (value: string) => {
		props.updateBillingAddressInfo({ Country: value });
	};

	const setAddress1 = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.updateBillingAddressInfo({ Address1: event.target.value });
	};

	const setAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.updateBillingAddressInfo({ Address2: event.target.value });
	};

	const setCity = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.updateBillingAddressInfo({ City: event.target.value });
	};
	const setZip = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.updateBillingAddressInfo({ Zip: event.target.value });
	};

	const setStateOrProvince = (value: string) => {
		props.updateBillingAddressInfo({ State: value });
	};

	const countryAsyncContent = useAsync(async () => {
		return { countries: await getCountries() };
	}, []);

	const subdivisionsAsyncContent = useAsync(async () => {
		return { subdivisions: await getSubdivisions(props.billingAddress.Country || 'US') };
	}, [props.billingAddress.Country]);

	return (
		<Form
			form={form}
			layout="vertical"
			style={{
				border: `1px solid ${token.colorBorder}`,
				borderRadius: token.borderRadius,
				padding: token.padding,
			}}
		>
			<Form.Item label={t('convert_to_paid.billing_address.country')}>
				<Select
					onChange={setCountry}
					value={props.billingAddress.Country}
					options={
						countryAsyncContent.result
							? countryAsyncContent.result?.countries.map(country => ({
									value: country.Id,
									label: country.Name,
							  }))
							: []
					}
					disabled={props.disableCountries || props.isSFHybrisCCInfoSuccessfullyUpdated}
					data-testid="country"
				/>
			</Form.Item>

			{isCountrySupported(props.billingAddress.Country) ? (
				<>
					<Form.Item label={t('convert_to_paid.billing_address.street')} required>
						<Input
							value={props.billingAddress.Address1}
							onChange={setAddress1}
							data-testid="street-address"
						/>
					</Form.Item>
					<Form.Item>
						<Input
							value={props.billingAddress.Address2}
							onChange={setAddress2}
							data-testid="street-address2"
						/>
					</Form.Item>
					<Form.Item label={t('convert_to_paid.billing_address.city')} required>
						<Input
							value={props.billingAddress.City}
							onChange={setCity}
							data-testid="city"
						/>
					</Form.Item>
					<Row gutter={12} justify="space-evenly">
						<Col span={12}>
							<Form.Item label={t('convert_to_paid.billing_address.state')} required>
								<Select
									onChange={setStateOrProvince}
									value={props.billingAddress.State}
									options={
										subdivisionsAsyncContent.result
											? subdivisionsAsyncContent.result?.subdivisions.map(
													subdivision => ({
														value: subdivision.Id,
														label: subdivision.Name,
													})
											  )
											: []
									}
									data-testid="state-or-province"
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item label={t('convert_to_paid.billing_address.zip')}>
								<Input
									value={props.billingAddress.Zip}
									onChange={setZip}
									data-testid="zip"
								/>
							</Form.Item>
						</Col>
					</Row>
				</>
			) : (
				<Alert
					message={t('convert_to_paid.disable_trial_conversion_message')}
					type="info"
					showIcon
					data-testid="disable-trial-conversion-message"
				/>
			)}
		</Form>
	);
};
