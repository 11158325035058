import * as React from 'react';
import { colorPalette, sizes } from '@sharefiledev/antd-config';
import { Card, Flex, Grid, theme, Typography } from 'antd';
import { t } from '../../../translate';
import { formatCurrency } from '../../currencyFormatter';

const { Text, Title } = Typography;
const { useBreakpoint } = Grid;

export interface Props {
	label: string;
	details?: string;
	price: number;
	onClick(): void;
	isSelected: boolean;
	currencyCode: string;
}

export const PriceCard = (props: Props) => {
	const { token } = theme.useToken();
	const screens = useBreakpoint();

	const isPlanSelected = (): React.CSSProperties => {
		return props.isSelected
			? {
					borderColor: token.colorBorder,
					background: colorPalette.lavender1,
					cursor: 'pointer',
			  }
			: {
					borderColor: token.colorBorder,
					background: 'none',
					cursor: 'pointer',
			  };
	};

	return (
		<Card
			onClick={props.onClick}
			key={props.price}
			style={isPlanSelected()}
			styles={{ body: { padding: screens.lg ? sizes.MS : sizes.SM } }}
			data-testid={`${props.label}_card`}
		>
			<Flex vertical>
				<Title level={5}>
					{props.label} {props.details}
				</Title>
				<Title level={screens.lg ? 1 : 3} style={{ margin: 0 }}>
					{formatCurrency(props.price, props.currencyCode)}
				</Title>
				<Text>{t('convert_to_paid.month')}</Text>
			</Flex>
		</Card>
	);
};
