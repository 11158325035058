import * as React from 'react';
import { getFeatureFlagValue } from '@citrite/feature-flags';
import { Account, Api, User, UserRole } from '@citrite/sf-api';
import type { PiletApi, PiletUserContext } from '@sharefiledev/sharefile-appshell';
import { ApplicationContextProvider } from './ApplicationContextProvider';
import ConvertToPaid from './ConvertToPaid/ConvertToPaid';
import { AsyncManagePlan } from './EditBilling';
import { FeatureFlag } from './featureFlags';
import { showManagePlan } from './managePlanCheck';
import { AdminBillingRoute, ConvertFreeTrialRoute, ManagePlanRoute } from './routes';

export function setup(piletApi: PiletApi) {
	const context: PiletUserContext = piletApi.sf.piletUserContext.get();
	const account = context.account;
	const user = context.user;
	const accountId = context.account.Id;
	const userId = context.user.Id;
	const isFreeTrial = context.account.IsFreeTrial ?? true;
	const enablePilet = isPiletEnabled();
	const isFINRA = context.account.Preferences?.IsFINRA ?? false;
	const isVDR = (context.account.PlanTrack ?? '') === 'VDR';
	piletApi.sf.registerSfApiConfigHandler(Api.configure);

	if (process.env.NODE_ENV === 'development' || enablePilet) {
		if (
			accountId &&
			userId &&
			getFeatureFlagValue(FeatureFlag.EnableNewManagePlanPage, false) &&
			showManagePlan(context)
		) {
			piletApi.registerPage(ManagePlanRoute, () => (
				<ApplicationContextProvider piletApi={piletApi}>
					<AsyncManagePlan
						accountId={accountId}
						userId={userId}
						isFreeTrial={isFreeTrial}
						account={account}
						datePreferences={context.user.Preferences?.DateFormat}
						timePreferences={context.user.Preferences?.TimeFormat}
						isVDR={isVDR}
						isFINRA={isFINRA}
					/>
				</ApplicationContextProvider>
			));

			piletApi.sf.registerLeftSecondaryNavChildComponent({
				href: ManagePlanRoute,
				title: () => 'Manage Plan',
				weight: 200,
				parent: AdminBillingRoute,
			});
		}

		if (showConvertToPaid(user, account)) {
			piletApi.registerPage(`${ConvertFreeTrialRoute}/:orderId?/:successFlag?`, () => (
				<ApplicationContextProvider piletApi={piletApi}>
					<ConvertToPaid />
				</ApplicationContextProvider>
			));

			piletApi.sf.registerLeftSecondaryNavChildComponent({
				href: ConvertFreeTrialRoute,
				title: () => 'Convert Free Trial',
				weight: 200,
				parent: AdminBillingRoute,
			});
		}
	}
}

function isPiletEnabled(): boolean {
	return getFeatureFlagValue(FeatureFlag.EnableBillingPilet) ?? false;
}

function showBillingInfo(account: Account, userId: string) {
	return (
		account.Preferences?.ShowBillingInfo ||
		(account.Preferences?.HideBillingInfoExceptions || '').indexOf(userId) !== -1
	);
}

function showConvertLink(account: Account) {
	return account.IsFreeTrial && account.ConversionType === 'manual';
}

function showConvertToPaid(user: User, account: Account) {
	if (!account.CanConvertFreeTrial) {
		return false;
	}

	if (!showConvertLink(account)) {
		return false;
	}

	if (!user.Roles?.includes(UserRole.AdminBilling)) {
		return false;
	}

	if (!showBillingInfo(account, user.Id!)) {
		return false;
	}

	if (account.BillingType !== 'Hybris' && account.BillingType !== 'SFHybris') {
		return false;
	}

	if (!getFeatureFlagValue(FeatureFlag.TrialConversionPageUsePilet, false)) {
		return false;
	}

	return true;
}
